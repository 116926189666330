import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import { Form, FormRule } from '@asdeporte/plus-ui'
import { recoveryPassword } from '@services/users'
import { toast } from 'react-toastify'

export interface IUseRecoveryPasswordProps {
  lng: string
}

export const useRecoveryPassword = ({ lng }: IUseRecoveryPasswordProps) => {
  const [form] = Form.useForm()
  const { t: transl } = useTranslation()
  const [email, setEmail] = useState('')

  const getTranslation = (from: 'common', key: string) => {
    return transl(`${from}:${key}`, { lng: lng })
  }

  const rules: { [x: string]: FormRule[] } = useMemo(
    () => ({
      email: [
        {
          required: true,
          message: getTranslation('common', 'labels.required'),
        },
        {
          email: { ignore_max_length: true },
          message: getTranslation('common', 'validations.invalidEmail'),
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const onSubmit = async () => {
    const isValid = await form.validate()

    if (isValid === true) {
      const sent = await recoveryPassword(email)
      if (sent) {
        toast.success(getTranslation('common', 'states.sentEmail'))
        form.reset({ type: 'empty' })
      }
    }
  }

  return {
    form,
    email,
    rules,
    setEmail,
    onSubmit,
  }
}
