import React, { useContext, useState } from 'react'
import dynamic from 'next/dynamic'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import _ from 'lodash'
import { Form, Dialog, Loading } from '@asdeporte/plus-ui'
import Header from '@asdeporte/apolo-components/cjs/Header'
import Navbar from '@asdeporte/apolo-components/cjs/Navbar'
import ForgotPasswordForm from '@asdeporte/apolo-components/cjs/ForgotPasswordForm'
import WelcomeMessage from '@asdeporte/apolo-components/cjs/WelcomeMessage'
import { INavbarItem } from '@asdeporte/apolo-components/cjs/Navbar/interfaces'
import { ROLES_SLUG } from '@utils/constants'
import { useRecoveryPassword } from '@hooks/useRecoveryPassword'
import { useDashboard } from './useDashboard'

import '@asdeporte/apolo-components/es/Navbar/style/index.css'
import '@asdeporte/apolo-components/es/Header/style/index.css'
import '@asdeporte/apolo-components/es/WelcomeMessage/style/index.css'
import { OrganizationContext } from '@context/organization/OrganizationContext'

const CrispWithNoSSR = dynamic(() => import('../../../components/Crisp'), {
  ssr: false,
})

interface IDashboardLayoutProps {
  children: React.ReactNode
  lng: string
}

export const DashboardLayout: React.FC<IDashboardLayoutProps> = ({
  children,
  lng,
}) => {
  const router = useRouter()
  const { data: session, status } = useSession()
  const { form, rules, email, setEmail, onSubmit } = useRecoveryPassword({
    lng,
  })
  const { loadings, roles, organizations, currentOrganization, handleCurrent } =
    useContext(OrganizationContext)
  const [welcomeVisible, setWelcomeVisible] = useState(
    organizations.length === 0
  )
  const {
    hydrated,
    profile,
    userActions,
    getTranslation,
    handleSidebar,
    visible,
    setVisible,
    setHandleSidebar,
  } = useDashboard({ lng })

  const navbarItems: INavbarItem[] = [
    {
      icon: { value: 'icon-dashboard' },
      content: 'Dashboard',
      href: '/dashboard',
    },
    {
      content: getTranslation('common', 'navigation.events'),
      icon: {
        value: 'icon-calendar-check',
        cssProps: {
          fontSize: '20px',
        },
      },
      hidden: !roles.some(rol => rol.slug === ROLES_SLUG.EVENTS),
      children: [
        {
          href: '/events/new-event',
          hidden: !roles.some(rol => rol.slug === ROLES_SLUG.EVENTS),
          content: getTranslation('common', 'navigation.newEvent'),
        },
        {
          href: '/events',
          content: getTranslation('common', 'navigation.seeAll'),
        },
      ],
    },
    {
      content: getTranslation('common', 'navigation.users'),
      icon: {
        value: 'icon-user',
        cssProps: {
          fontSize: '18px',
          marginLeft: '-1px',
        },
      },
      hidden: !roles.some(rol => rol.slug === ROLES_SLUG.WEB_USERS),
      children: [
        {
          href: '/users/new-user',
          hidden: !roles.some(rol => rol.slug === ROLES_SLUG.WEB_USERS),
          content: getTranslation('common', 'navigation.createUser'),
        },
        {
          href: '/users',
          hidden: !roles.some(rol => rol.slug === ROLES_SLUG.WEB_USERS),
          content: getTranslation('common', 'navigation.listUser'),
        },
        {
          href: '/users/merge',
          hidden: !roles.some(rol => rol.slug === ROLES_SLUG.WEB_USERS),
          content: getTranslation('common', 'navigation.merged'),
        },
      ],
    },
    {
      content: getTranslation('common', 'navigation.website'),
      icon: { value: 'icon-certificate' },
      hidden: !roles.some(
        rol =>
          rol.slug === ROLES_SLUG.WEB_STAGE ||
          rol.slug === ROLES_SLUG.WEB_CAROUSEL ||
          rol.slug === ROLES_SLUG.WEB_SERIALS ||
          rol.slug === ROLES_SLUG.EVENT_SPONSORS ||
          rol.slug === ROLES_SLUG.INBODY
      ),
      children: [
        {
          href: '/website/stage',
          hidden: !roles.some(rol => rol.slug === ROLES_SLUG.WEB_STAGE),
          content: getTranslation('common', 'navigation.stage'),
        },
        {
          href: '/website/carousel',
          hidden: !roles.some(rol => rol.slug === ROLES_SLUG.WEB_CAROUSEL),
          content: getTranslation('common', 'navigation.carousel'),
        },
        {
          href: '/website/serial',
          hidden: !roles.some(rol => rol.slug === ROLES_SLUG.WEB_SERIALS),
          content: getTranslation('common', 'navigation.serial'),
        },
        {
          href: '/website/sponsors',
          hidden: !roles.some(rol => rol.slug === ROLES_SLUG.EVENT_SPONSORS),
          content: getTranslation('common', 'navigation.sponsors'),
        },
        {
          href: '/website/inbody',
          hidden: !roles.some(rol => rol.slug === ROLES_SLUG.INBODY),
          content: getTranslation('common', 'navigation.inbody'),
        },
      ],
    },
    {
      icon: {
        value: 'icon-paper',
        cssProps: {
          fontSize: '18px',
        },
      },
      content: 'FMTRI',
      hidden:
        roles.filter(
          rol =>
            rol.slug === ROLES_SLUG.FMTRI_AFFILIATE_USERS ||
            rol.slug === ROLES_SLUG.FMTRI_AFFILIATION_TYPES ||
            rol.slug === ROLES_SLUG.FMTRI_AFFILIATIONS
        ).length === 0,
      children: [
        {
          href: '/fmtri/new-affiliation',
          content: getTranslation('common', 'navigation.newAffiliation'),
          hidden: !roles.some(
            rol => rol.slug === ROLES_SLUG.FMTRI_AFFILIATE_USERS
          ),
        },
        {
          href: '/fmtri/types',
          content: getTranslation('common', 'navigation.affiliationType'),
          hidden: !roles.some(
            rol => rol.slug === ROLES_SLUG.FMTRI_AFFILIATION_TYPES
          ),
        },
        {
          href: '/fmtri/affiliations',
          content: getTranslation('common', 'navigation.affiliations'),
          hidden: !roles.some(
            rol => rol.slug === ROLES_SLUG.FMTRI_AFFILIATIONS
          ),
        },
      ],
    },
    {
      icon: {
        value: 'icon-wallet',
        cssProps: {
          fontSize: '23px',
          marginLeft: '-3px',
        },
      },
      hidden: !roles.some(rol => rol.slug === ROLES_SLUG.EXTERNAL_INSCRIPTIONS),
      content: getTranslation('common', 'navigation.inscriptions'),
      children: [
        {
          href: '/inscriptions/list',
          content: getTranslation('common', 'navigation.myInscriptions'),
        },
        {
          href: '/inscriptions/new-inscription',
          content: getTranslation('common', 'navigation.newInscription'),
        },
      ],
    },
    {
      icon: {
        value: 'icon-descount',
        cssProps: {
          fontSize: '24px',
          marginLeft: '-3px',
        },
      },
      hidden: !roles.some(rol => rol.slug === ROLES_SLUG.PROMOTION_BIN),
      content: getTranslation('common', 'navigation.promotions'),
      href: '/promotions',
    },
    {
      icon: {
        value: 'icon-vector-2',
        cssProps: {
          fontSize: '20px',
          marginBottom: '3px',
          marginRight: '13px',
        },
      },
      hidden: !roles.some(rol => rol.slug === ROLES_SLUG.ORGANIZATIONS),
      content: 'Empresas',
      href: '/organizations',
    },
    {
      icon: {
        value: 'icon-horn',
        cssProps: {
          fontSize: '22px',
          marginTop: '2px',
          marginRight: '13px',
        },
      },
      hidden: !roles.some(rol => rol.slug === ROLES_SLUG.PUSH_NOTIFICATIONS),
      content: getTranslation('common', 'navigation.notices'),
      children: [
        {
          content: getTranslation('common', 'navigation.notifications'),
          href: '/notices/notifications',
          hidden: !roles.some(
            rol => rol.slug === ROLES_SLUG.PUSH_NOTIFICATIONS
          ),
        },
        // {
        //   content: getTranslation('common', 'navigation.messages'),
        //   href: '/notices/messages',
        // },
      ],
    },
    // {
    //   icon: NAVBAR_ICONS.wallet,
    //   link: <Link href="/wallet">Wallet</Link>,
    // },
    // {
    //   icon: NAVBAR_ICONS.delivery,
    //   link: (
    //     <Link href="/delivery">
    //       {getTranslation('common', 'navigation.deliveryAtHome')}
    //     </Link>
    //   ),
    // },
  ]

  if (status === 'loading' || loadings.organizations || loadings.roles)
    return <Loading />

  if (status === 'unauthenticated') router.push('/login')

  if (
    !organizations.length &&
    !['/organizations/new-organization', '/profile'].includes(router.pathname)
  ) {
    router.push('/organizations/new-organization')
  }

  return (
    <>
      {hydrated && (
        <>
          <CrispWithNoSSR />
          <Header
            profile={profile}
            actions={userActions}
            organizations={organizations}
            orgSelected={currentOrganization}
            handleOrgSelected={org => handleCurrent(org)}
            handleNavigation={() => setHandleSidebar(prev => !prev)}
            goToAllOrganizations={() =>
              router.push('/organizations/my-organization')
            }
            goToCreateOrganization={() =>
              router.push('/organizations/new-organization')
            }
          />
          <div
            className="wrapper"
            style={{
              display: organizations.length ? 'flex' : 'block',
            }}
          >
            {organizations.length ? (
              <Navbar
                currentMenuItem={''}
                navbarItems={navbarItems}
                actions={userActions}
                profile={profile}
                orgSelected={currentOrganization}
                organizations={organizations}
                mobileView={handleSidebar}
                goToAllOrganizations={() =>
                  router.push('/organizations/my-organization')
                }
                goToCreateOrganization={() =>
                  router.push('/organizations/new-organization')
                }
                handleOrgSelected={org => handleCurrent(org)}
              />
            ) : null}
            <div style={{ position: 'relative', width: '100%' }}>
              {children}
            </div>
          </div>

          {organizations.length === 0 ? (
            <WelcomeMessage
              name={session.user.name}
              visible={welcomeVisible}
              handleClose={() => setWelcomeVisible(false)}
            />
          ) : null}

          <Dialog
            visible={visible}
            width={400}
            onClose={() => setVisible(false)}
            cancelBtn={false}
            confirmBtn={false}
            header={
              <h3>{getTranslation('common', 'recoveryPassword.title')}</h3>
            }
            style={{ padding: '16px 24px' }}
          >
            <Form form={form} rules={rules} onSubmit={onSubmit}>
              <ForgotPasswordForm
                onCancel={() => setVisible(false)}
                email={email}
                handleEmail={setEmail}
              />
            </Form>
          </Dialog>
        </>
      )}
    </>
  )
}

export default DashboardLayout
