import { useEffect, useState } from 'react'
import { signOut, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import {
  IAction,
  IProfile,
} from '@asdeporte/apolo-components/cjs/Header/interface'

export interface IUseDashboardProps {
  lng: string
}

export const useDashboard = ({ lng }: IUseDashboardProps) => {
  const router = useRouter()
  const { data: session } = useSession()
  const { t: transl } = useTranslation()
  const [hydrated, setHydrated] = useState(false)
  const [profile, setProfile] = useState<IProfile>()
  const [visible, setVisible] = useState(false)
  // const [currentMenuValue, setCurrentMenuValue] = useState('')
  const [handleSidebar, setHandleSidebar] = useState(false)

  const userActions: IAction[] = [
    {
      label: 'Mi cuenta',
      key: 'my-account',
      onClick: () => router.push('/profile'),
    },
    {
      label: 'Cambiar contraseña',
      key: 'change-password',
      onClick: () => setVisible(true),
    },
    // {
    //   label: 'Agregar usuario',
    //   key: 'add-user',
    //   onClick: () => router.push('/users/new-user'),
    // },
    // {
    //   label: 'Usuarios',
    //   key: 'users',
    //   onClick: () => router.push('/users'),
    // },
    {
      label: 'Centro de descargas',
      key: 'download-center',
      onClick: () => router.push('/download-center'),
    },
    {
      label: 'Cerrar sesión',
      key: 'logout',
      onClick: () => logout(),
    },
  ]

  useEffect(() => {
    const init = () => {
      if (session) {
        setProfile({
          name: session.user.name,
          image: session.user.picture,
          first_last_name: session.user.first_last_name,
          second_last_name: session.user.second_last_name,
          userid: session.user.asdeporteId,
        })
        setHydrated(true)
      }
    }
    init()
  }, [session])

  // useEffect(() => {
  //   if (hydrated) {
  //     setCurrentMenuValue(router.pathname)
  //   }
  // }, [router.pathname, hydrated])

  const logout = async () => {
    await signOut({ redirect: true, callbackUrl: '/login' })
  }

  const getTranslation = (from: 'common', key: string) => {
    return transl(`${from}:${key}`, { lng: lng })
  }

  return {
    hydrated,
    // currentMenuValue,
    profile,
    userActions,
    getTranslation,
    visible,
    setVisible,
    handleSidebar,
    setHandleSidebar,
  }
}
